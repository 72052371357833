import { graphql, HeadFC, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import SEO from "../components/SEO"
import useSiteMetadata from "../hooks/useSiteMetadata"
import Faq from "../components/PPC/ReactDevPpc/Faq"
import Contact from "../components/Contact"

import ArrowWhite from "../assets/img/arrow-right.webp"
import StarImage from "../assets/img/star.png"
import ClutchLogo from "../assets/img/cluch.png"
import animatedIcon from "../assets/img/Contact-us-animation4.gif"
import animatedIconWhite from "../assets/img/Contact-us-animation-white.gif"
import TestimonialSlider from "../components/DashboardTestimonials/TestimonialSlider"
import EcoSystem from "../components/EcoSystemComponent"
import BannerSlider from "../components/BannerSlider"

const PseoWebDeveloperPage = ({
    data: {
        pseoWebDeveloperJson: post,
    },
}) => {
    return (
        <>
            <section className="pt-[7.625rem] pb-20 md:pt-36 lg:pt-40 lg:pb-28 bg-[#F8F7F3]">
                <div className="container">
                    <div className="max-w-[60rem] mx-auto text-center">
                        <h1 className="text-black text-[2.25rem] md:text-[2.75rem] lg:text-[3.438rem] leading-[3.25rem] lg:leading-[3.981rem] pb-[1.688rem] md:pb-9 lg:pb-10 font-blancomedium">
                            {post.title_1.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                            <span className="inline-block text-orangecol">.</span>
                        </h1>
                        <p className="text-base lg:text-[1.125rem] lg:leading-[1.875rem]">
                            {post.description_1}
                        </p>
                        <Link
                            to="/contact-us/"
                            className="inline-flex gap-5 items-center bg-white-200 justify-start inline-white text-black border border-white shadow-1xl text-base rounded-3xl pl-6 py-1 pr-1 font-gorditamedium mt-10 mb-[1.875rem] md:mt-[3.063rem] md:mb-10 group ease-in duration-300"
                        >
                            {post.button_text}
                            <span className="inline-flex h-10 w-10 bg-primary justify-center items-center rounded-full relative overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent group-hover:border-primary">
                                <img
                                    src={ArrowWhite}
                                    alt="ArrowRight"
                                    className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-16 ease-in duration-300 w-[7px] h-[14px]"
                                />
                                <img
                                    src={ArrowWhite}
                                    alt="ArrowRight"
                                    className="absolute -left-16 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-1/2  group-hover:invert-[1] group-hover:brightness-[1] w-[7px] h-[14px] ease-in duration-300"
                                />
                            </span>
                        </Link>
                        {/* {!post.hideInspiringList && ( */}
                        <ul className="flex flex-wrap justify-center gap-x-9 gap-y-4 text-xs text-black leading-[0.809rem] font-gorditaregular pt-1 pb-[1.375rem] relative after:content-[''] after:block after:absolute after:bottom-0 after:left-0 after:right-0 after:w-full after:h-[0.063rem] after:bg-gradient-to-r from-[#C9C9C900] from-10% via-[#C9C9C9] via-30% to-[#C9C9C900] to-90%">
                            <li className="relative pl-5">
                                <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-3.5 w-3.5 absolute top-[-0.125rem] left-0"></span> 7-day free trial
                            </li>
                            <li className="relative pl-5">
                                <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-3.5 w-3.5 absolute top-[-0.125rem] left-0"></span> On board within 48 hours
                            </li>
                            <li className="relative pl-5">
                                <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-3.5 w-3.5 absolute top-[-0.125rem] left-0"></span> Flexible scale up or down
                            </li>
                            <li className="relative pl-5">
                                <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-3.5 w-3.5 absolute top-[-0.125rem] left-0"></span> Simple contracts
                            </li>
                        </ul>
                        {/* )} */}


                        <div className="text-center text-xs leading-[0.938rem] mt-[1.625rem] flex items-center gap-2 flex-wrap align-middle justify-center">
                            <span className="text-sm md:text-base">4.9/5</span>
                            <span className="flex flex-nowrap items-center gap-[0.188rem]">
                                <img
                                    src={StarImage}
                                    alt="Star icon"
                                    className="max-w-[0.75rem]"
                                />
                                <img
                                    src={StarImage}
                                    alt="Star icon"
                                    className="max-w-[0.75rem]"
                                />
                                <img
                                    src={StarImage}
                                    alt="Star icon"
                                    className="max-w-[0.75rem]"
                                />
                                <img
                                    src={StarImage}
                                    alt="Star icon"
                                    className="max-w-[0.75rem]"
                                />
                                <img
                                    src={StarImage}
                                    alt="Star icon"
                                    className="max-w-[0.75rem]"
                                />
                            </span>
                            <span>based on <em className="font-gorditamedium not-italic">41</em> verified reviews</span>
                            <img
                                src={ClutchLogo}
                                alt="Clutch"
                                className="max-w-[5.25rem]"
                            />
                        </div>
                    </div>
                    <div className="mt-[3.188rem] lg:mt-[4.625rem]">
                        <BannerSlider bannerCount={5} swiperButtons="bullets" sliderClassWeb="!justify-center" />
                    </div>
                </div>
            </section>

            <section className="py-10 md:py-12 lg:py-14">
                <div className="container">
                    <div className="title-sec text-center m-auto max-w-[59.375rem]">
                        <h2 className="text-black text-[2rem] lg:text-[2.625rem] leading-[2.813rem] lg:leading-[3.375rem] font-blancomedium">
                            {post.hiring.title.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                        </h2>
                        {post.description && <span className="inline-block py-2 px-[1.125rem] md:px-6 rounded-[6.25rem] bg-[#E8E3FF] text-xs md:text-sm lg:text-base mb-2 md:mb-5 font-gorditaregular leading-[1.125rem] md:leading-[1.813rem] mt-10">
                            {post.hiring.description}
                        </span>}
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-y-[1.875rem] lg:gap-y-10 md:gap-x-10 lg:gap-x-[4.5rem] mt-10 md:mt-[3.125rem]">
                        {post.hiring.points.map(({ pointImage, title, description }, idx) => {
                            return (
                                <div className="" key={idx}>
                                    <figure className="h-11 w-11 mb-5 md:mb-[1.875rem]">
                                        <img loading="lazy" src={pointImage.publicURL} alt={title[1]} />
                                    </figure>
                                    <span className={`inline-block italic py-2 px-[1.125rem] md:px-6 rounded-[6.25rem] ${title[0]} text-xs md:text-sm lg:text-base mb-2 md:mb-5 font-gorditamedium leading-[1.125rem] md:leading-[1.813rem]`}>
                                        {title[1]}
                                    </span>
                                    <p className="text-[0.938rem] md:text-base leading-[1.625rem]">
                                        {description}
                                    </p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>

            <section className="py-10 md:py-12 lg:py-14">
                <div className="container">
                    <div className="flex flex-wrap border border-cream items-center justify-between gap-10 px-[1.875rem] pt-10 pb-[3.125rem] md:pt-[3.25rem] md:pb-[3.625rem] md:px-[4.375rem] md:bg-[url('../assets/img/tech-bg.webp')] bg-[url('../assets/img/tech-bg-mob.webp')] bg-no-repeat bg-cover bg-center rounded-[1.25rem] overflow-hidden">
                        <h2 className="font-blancomedium text-[1.75rem] md:text-3xl lg:text-4xl w-full md:w-[33.125rem] leading-[2.938rem]">
                            {post.build_team.text.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                        </h2>
                        <Link
                            to="/contact-us/"
                            className="inline-flex m-0 sm:w-auto gap-5 items-center bg-white-200 md:justify-start inline-white text-black border border-white shadow-1xl text-base rounded-3xl pl-7 py-1 pr-[0.313rem] font-gorditamedium group ease-in duration-300 justify-between"
                        >
                            {post.build_team.button_text}
                            <span className="inline-flex bg-primary justify-center items-center rounded-full relative h-11 w-11 overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent group-hover:border-primary">
                                <img
                                    src={ArrowWhite}
                                    alt="ArrowRight"
                                    className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-16 ease-in duration-300 w-[7px] h-[14px]"
                                />
                                <img
                                    src={ArrowWhite}
                                    alt="ArrowRight"
                                    className="absolute -left-16 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-1/2  group-hover:invert-[1] group-hover:brightness-[1] w-[7px] h-[14px] ease-in duration-300"
                                />
                            </span>
                        </Link>
                    </div>
                </div>
            </section>


            <section className="py-10 md:py-12 lg:py-14 overflow-hidden">
                <div className="container">
                    <div className="title-sec text-center md:max-w-[47.5rem] m-auto">
                        <h2 className="text-black text-[2rem] lg:text-[2.625rem] leading-[2.813rem] lg:leading-[3.375rem] font-blancomedium">
                            {post.top_developer.title.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                            <span className="inline-block text-orangecol">.</span>
                        </h2>
                        <p className="text-[0.938rem] md:text-base leading-[1.875rem] mt-5">
                            {post.top_developer.description}
                        </p>
                    </div>

                    <ul className="grid grid-cols-1 lg:grid-cols-4 gap-x-[1.688rem] gap-y-[1.875rem] mt-10 relative z-10 before:content-['*'] before:h-[0.063rem] before:absolute before:w-[500%] before:bg-black before:top-8 before:left-[50%] before:-translate-x-1/2 before:-z-10 max-lg:before:w-[0.063rem] max-lg:before:max-lg:h-full max-lg:before:top-0 max-lg:before:left-6 max-lg:before:translate-x-0">
                        <li className="relative pl-16 lg:pl-0">
                            <span className="max-lg:absolute max-lg:left-0 max-lg:-top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] border border-black rounded-[50%] flex items-center justify-center mb-5 bg-[#ffffff] font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">1</span>
                            <h4 className="font-blancomedium text-2xl mb-[0.875rem] md:mb-5">Screening candidates</h4>
                            {post.top_developer.screening_tag.length > 0 &&
                                <span className="inline-block py-[0.438rem] px-[1.188rem] rounded-[6.25rem] bg-[#E8E3FF] text-xs md:text-sm lg:text-base mb-[0.875rem] md:mb-5 font-gorditaregular leading-[1.125rem] md:leading-[1.813rem]">
                                    {post.top_developer.screening_tag.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                                </span>}
                            <p className="text-[0.938rem] md:text-base leading-[1.625rem]">
                                {post.top_developer.screening_text}
                            </p>
                        </li>
                        <li className="relative pl-16 lg:pl-0">
                            <span className="max-lg:absolute max-lg:left-0 max-lg:-top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] border border-black rounded-[50%] flex items-center justify-center mb-5 bg-[#ffffff] font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">2</span>
                            <h4 className="font-blancomedium text-2xl mb-[0.875rem] md:mb-5">In-depth review & test</h4>
                            {post.top_developer.review_tag.length > 0 &&
                                <span className="inline-block py-[0.438rem] px-[1.188rem] rounded-[6.25rem] bg-[#FFF2E3] text-xs md:text-sm lg:text-base mb-[0.875rem] md:mb-5 font-gorditaregular leading-[1.125rem] md:leading-[1.813rem]">
                                    {post.top_developer.review_tag.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                                </span>}
                            <p className="text-[0.938rem] md:text-base leading-[1.625rem]">
                                {post.top_developer.review_text}
                            </p>
                        </li>
                        <li className="relative pl-16 lg:pl-0">
                            <span className="max-lg:absolute max-lg:left-0 max-lg:-top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] border border-black rounded-[50%] flex items-center justify-center mb-5 bg-[#ffffff] font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">3</span>
                            <h4 className="font-blancomedium text-2xl mb-[0.875rem] md:mb-5">Live task</h4>
                            {post.top_developer.live_task_tag.length > 0 &&
                                <span className="inline-block py-[0.438rem] px-[1.188rem] rounded-[6.25rem] bg-[#E8FBFF] text-xs md:text-sm lg:text-base mb-[0.875rem] md:mb-5 font-gorditaregular leading-[1.125rem] md:leading-[1.813rem]">
                                    {post.top_developer.live_task_tag.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                                </span>}
                            <p className="text-[0.938rem] md:text-base leading-[1.625rem]">
                                {post.top_developer.live_task_text}
                            </p>
                        </li>
                        <li className="relative pl-16 lg:pl-0 max-lg:bg-[#ffffff] bg-opacity-100">
                            <span className="max-lg:absolute max-lg:left-0 max-lg:-top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] border border-black rounded-[50%] flex items-center justify-center mb-5 bg-[#ffffff] font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">4</span>
                            <h4 className="font-blancomedium text-2xl mb-[0.875rem] md:mb-5">Consistent work</h4>
                            {post.top_developer.consitent_work_tag.length > 0 &&
                                <span className="inline-block py-[0.438rem] px-[1.188rem] rounded-[6.25rem] bg-[#E0FFEB] text-xs md:text-sm lg:text-base mb-[0.875rem] md:mb-5 font-gorditaregular leading-[1.125rem] md:leading-[1.813rem]">
                                    {post.top_developer.consitent_work_tag.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                                </span>}
                            <p className="text-[0.938rem] md:text-base leading-[1.625rem]">
                                {post.top_developer.consitent_work_text}
                            </p>
                        </li>
                    </ul>
                </div>
            </section>

            <section className="py-10 md:py-12 lg:py-14">
                <div className="container">
                    <div className="flex flex-wrap border border-cream items-center justify-between gap-10 px-[1.875rem] pt-10 pb-[3.125rem] md:pt-[3.25rem] md:pb-[3.625rem] md:px-[4.375rem] md:bg-[url('../assets/img/tech-bg.webp')] bg-[url('../assets/img/tech-bg-mob.webp')] bg-no-repeat bg-cover bg-center rounded-[1.25rem] overflow-hidden">
                        <h2 className="font-blancomedium text-[1.75rem] md:text-3xl lg:text-4xl w-full md:max-w-[41.688rem] leading-[2.938rem]">
                            {post.best_web_developers_text.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                        </h2>
                        <Link
                            to="/contact-us/"
                            className="inline-flex m-0 sm:w-auto gap-5 items-center bg-white-200 md:justify-start inline-white text-black border border-white shadow-1xl text-base rounded-3xl pl-7 py-1 pr-[0.313rem] font-gorditamedium group ease-in duration-300 justify-between"
                        >
                            {post.best_web_developers_button_text}
                            <span className="inline-flex bg-primary justify-center items-center rounded-full relative h-11 w-11 overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent group-hover:border-primary">
                                <img
                                    src={ArrowWhite}
                                    alt="ArrowRight"
                                    className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-16 ease-in duration-300 w-[7px] h-[14px]"
                                />
                                <img
                                    src={ArrowWhite}
                                    alt="ArrowRight"
                                    className="absolute -left-16 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-1/2  group-hover:invert-[1] group-hover:brightness-[1] w-[7px] h-[14px] ease-in duration-300"
                                />
                            </span>
                        </Link>
                    </div>
                </div>
            </section>

            <section className="py-10 md:py-12 lg:py-14">
                <div className="container">
                    <div className="title-sec text-center md:max-w-[47.5rem] mx-auto mb-[2.313rem] lg:mb-[4.375rem]">
                        <h2 className="text-black text-[2rem] lg:text-[2.625rem] leading-[2.813rem] lg:leading-[3.375rem] font-blancomedium">
                            {post.reasons_to_choose.title.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                            <span className="inline-block text-orangecol">.</span>
                        </h2>
                    </div>

                    <div className="before:clear-both before:content-[''] before:block after:content-[''] after:block after:clear-both ">
                        <div className="lg:float-left p-10 md:p-[3.125rem] bg-[#F8F7F2] rounded-[1.25rem] lg:max-w-[27.313rem] lg:sticky md:top-24 md:left-0">
                            <p className="text-lg md:text-xl lg:text-[1.375rem] italic leading-[2.063rem]">
                                “{post.reasons_to_choose.box_quote}”
                                <span className="block not-italic mt-[1.875rem] font-gorditamedium text-base md:text-lg">
                                    {post.reasons_to_choose.box_bottom}
                                </span>
                            </p>
                        </div>
                        <div className="lg:float-right lg:max-w-[calc(100%-27.313rem)] lg:pl-20">
                            <div className="grid grid-cols-1 gap-y-[3.125rem] mt-10 md:mt-[3.125rem]">
                                {post.reasons_to_choose.points.map(({ icon, title, description }, idx) => (
                                    <div key={idx} className="">
                                        <figure className="h-11 w-11 mb-5 md:mb-[1.875rem]">
                                            <img src={icon.publicURL} alt={title[1]} loading="lazy" />
                                        </figure>
                                        <span className={`inline-block italic py-2 px-[1.125rem] md:px-6 rounded-[6.25rem] ${title[0]} text-xs md:text-sm lg:text-base mb-2 md:mb-5 font-gorditamedium leading-[1.125rem] md:leading-[1.813rem]`}>
                                            {title[1]}
                                        </span>
                                        <p className="text-[0.938rem] md:text-base leading-[1.625rem]">
                                            {description}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container">
                    <div className="flex flex-wrap border border-cream items-center justify-between gap-10 px-[1.875rem] pt-10 pb-[3.125rem] md:pt-[3.25rem] md:pb-[3.625rem] md:px-[4.375rem] md:bg-[url('../assets/img/tech-bg.webp')] bg-[url('../assets/img/tech-bg-mob.webp')] bg-no-repeat bg-cover bg-center rounded-[1.25rem] overflow-hidden">
                        <h2 className="font-blancomedium text-[1.75rem] md:text-3xl lg:text-4xl w-full md:w-[29.188rem] leading-[2.938rem]">
                            {post.recruit.text.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                            <span className="inline-block text-orangecol">.</span>
                        </h2>
                        <Link
                            to="/contact-us/"
                            className="inline-flex w-70 sm:w-auto gap-5 items-center bg-white-200 md:justify-start inline-white text-black border border-white shadow-1xl text-base rounded-3xl pl-7 py-1 pr-[0.313rem] font-gorditamedium group ease-in duration-300 justify-between"
                        >
                            {post.recruit.button_text}
                            <span className="inline-flex bg-primary justify-center items-center rounded-full relative h-11 w-11 overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent group-hover:border-primary">
                                <img
                                    src={ArrowWhite}
                                    alt="ArrowRight"
                                    className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-16 ease-in duration-300 w-[7px] h-[14px]"
                                />
                                <img
                                    src={ArrowWhite}
                                    alt="ArrowRight"
                                    className="absolute -left-16 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-1/2  group-hover:invert-[1] group-hover:brightness-[1] w-[7px] h-[14px] ease-in duration-300"
                                />
                            </span>
                        </Link>
                    </div>
                </div>
            </section>

            <EcoSystem ecosystem={post.ecosystem} />

            <section className="py-10 md:py-12 lg:py-[3.438rem]">
                <div className="container">
                    <div className="title-sec text-center md:max-w-[47.5rem] m-auto">
                        <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-3 lg:pb-5 font-blancomedium">
                            {post.hire_developer_text.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                            <span className="inline-block text-orangecol">.</span>
                        </h2>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-[1.875rem] mt-[3.125rem] text-center">
                        <div className="py-12 px-[3.625rem] border-2 border-[#FFF2E3] rounded-[1.25rem]">
                            <span className="inline-block italic py-2 px-[1.125rem] md:px-6 rounded-[6.25rem] bg-[#E8E3FF] text-xs md:text-sm lg:text-base mb-10 md:mb-[2.875rem] font-gorditamedium leading-[1.125rem] md:leading-[1.813rem]">Hire on an hourly basis</span>
                            <div className="font-gorditamedium text-xl lg:text-2xl mb-[26px] md:mb-[5.375rem]"><span className="text-[2.875rem] lg:text-[3.375rem]">$15</span>/Hour</div>

                            <ul className="text-base text-black leading-[1.625] font-gorditaregular text-left">
                                <li className="relative pl-7 mb-[0.625rem]">
                                    <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-4 w-4 absolute top-[0.188rem] left-0"></span> Pay only for hours worked
                                </li>
                                <li className="relative pl-7">
                                    <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-4 w-4 absolute top-[0.188rem] left-0"></span> 8+ Ideal if looking for time and material model
                                </li>
                            </ul>
                            <Link
                                to="/contact-us/"
                                className="inline-flex m-auto sm:w-auto gap-5 items-center bg-white-200 justify-center md:justify-start text-black border border-black shadow-1xl text-base rounded-3xl pl-7 py-1 pr-[0.313rem] font-gorditamedium mt-[3.125rem] group ease-in duration-300"
                            >
                                Get Started
                                <span className="inline-flex w-[2.125rem] h-[2.125rem] bg-primary justify-center items-center rounded-full relative overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent group-hover:border-primary">
                                    <img
                                        src={ArrowWhite}
                                        alt="ArrowRight"
                                        className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-16 ease-in duration-300 w-[7px] h-[14px]"
                                    />
                                    <img
                                        src={ArrowWhite}
                                        alt="ArrowRight"
                                        className="absolute -left-16 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-1/2  group-hover:invert-[1] group-hover:brightness-[1] w-[7px] h-[14px] ease-in duration-300"
                                    />
                                </span>
                            </Link>
                        </div>

                        <div className="py-12 px-[3.625rem] border-2 border-[#F8F7F3] rounded-[1.25rem] bg-[#F8F7F3]">
                            <span className="inline-block italic py-2 px-[1.125rem] md:px-6 rounded-[6.25rem] bg-[#E8E3FF] text-xs md:text-sm lg:text-base mb-10 md:mb-[2.875rem] font-gorditamedium leading-[1.125rem] md:leading-[1.813rem]">Hire at a fixed monthly rate</span>
                            <div className="font-gorditamedium text-xl lg:text-2xl mb-[0.625rem]"><span className="text-[2.875rem] lg:text-[3.375rem]">$2200</span>/Month</div>
                            <span className="font-gorditamedium inline-block text-sm text-white bg-black italic py-1 pl-[0.813rem] pr-[1.063rem] rounded-[6.25rem] mb-[26px] md:mb-[2.5rem] leading-[1.813rem]">~ Save 10%</span>
                            <ul className="text-base text-black leading-[1.625] font-gorditaregular text-left">
                                <li className="relative pl-7 mb-[0.625rem]">
                                    <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-4 w-4 absolute top-[0.188rem] left-0"></span> 160 man-hours per month dedicated
                                </li>
                                <li className="relative pl-7">
                                    <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-4 w-4 absolute top-[0.188rem] left-0"></span> 8+ Ideal if you need dedicated developers
                                </li>
                            </ul>
                            <Link
                                to="/contact-us/"
                                className="inline-flex sm:w-auto gap-5 items-center bg-white-200 justify-between inline-white text-black border border-white shadow-1xl text-base rounded-3xl pl-7 py-1 pr-[0.313rem] font-gorditamedium mt-[3.125rem] group ease-in duration-300"
                            >
                                Get Started
                                <span className="inline-flex w-[2.125rem] h-[2.125rem] bg-primary justify-center items-center rounded-full relative overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent group-hover:border-primary">
                                    <img
                                        src={ArrowWhite}
                                        alt="ArrowRight"
                                        className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-16 ease-in duration-300 w-[7px] h-[14px]"
                                    />
                                    <img
                                        src={ArrowWhite}
                                        alt="ArrowRight"
                                        className="absolute -left-16 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-1/2  group-hover:invert-[1] group-hover:brightness-[1] w-[7px] h-[14px] ease-in duration-300"
                                    />
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-10 md:py-[1.875rem] lg:py-[3.438rem] testimonial-sec">
                <div className="container">
                    <div className="w-full text-center lg:pb-10 pb-9">
                        <div className="title-label text-center"><span className="font-gorditamedium inline-block bg-cream italic py-[0.563rem] px-[1.5rem] rounded-[6.25rem] mb-[0.625rem]">According to Clutch.co</span></div>
                        <h2 className="text-black text-[2rem] lg:text-[2.625rem] leading-[2.813rem] lg:leading-[3.375rem] font-blancomedium lg:max-w-[59.375rem] mx-auto mb-5">
                            {post.clutch_title.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                            <span className="inline-block text-orangecol">.</span>
                        </h2>
                        <p className="text-base lg:text-[1.125rem] lg:leading-[1.875rem]">
                            {post.clutch_description}
                        </p>
                    </div>

                    <TestimonialSlider />

                </div>
            </section >

            <section className="hiring-section bg-gradient-to-b from-[#F8F7F3] to-[rgba(248, 247, 243, 0.00)] pt-20 pb-10 lg:pt-[6.875rem] lg:pb-[3.75rem]">
                <div className="container">
                    <div className="title-wrap">
                        <h2 className="text-center text-black text-[2rem] lg:text-[2.625rem] leading-[2.813rem] lg:leading-[3.375rem] font-blancomedium lg:max-w-[47rem] my-0 mx-auto">
                            {post.hiring_benefits.title.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                            <span className="inline-block text-orangecol">.</span>
                        </h2>
                    </div>
                    <p className="usecase-title text-center text-lg md:text-xl lg:text-2xl font-gorditamedium leading-[1.45] pt-12 pb-10">Traditional Hiring VS Hiring with Techuz</p>

                    <div className="hiring-flow pb-[3.75rem]">
                        {post.hiring_benefits.points.map(({ title, left_content, right_content }, idx) => (
                            <div key={idx}>
                                <div className="flow-title text-center">
                                    <div className="shadow-1xl font-gorditaregular text-xs md:text-lg text-black rounded-full bg-white leading-[1.125rem] md:leading-[1.875rem] py-2 md:py-[0.875rem] px-7 md:px-[2.125rem] inline-block  bg-white-200">
                                        {title}
                                    </div>
                                </div>
                                <div className="flow-grid grid grid-cols-2">
                                    <div className="flow-content text-right pr-[1.875rem] pt-[3.125rem] border-r border-r-[#DFDCD3] md:pb-20 pb-0">
                                        {left_content[0] && <span className="flow-label inline-block border border-black rounded-[3.688rem] py-2 md:pb-[6px] md:py-[0.563rem] px-[1.375rem] md:px-[1.563rem] text-xs md:text-sm leading-none text-center ml-auto mb-[1.688rem] md:mb-5">
                                            {left_content[0]}
                                        </span>}
                                        <p className="text-base md:text-lg lg:text-2xl leading-6 md:leading-[2.125rem] mb-5 md:mb-[1.875rem] max-w-[27.75rem] ml-auto relative before:content-[''] before:w-[0.438rem] before:h-[0.438rem] before:bg-[#DFDCD3] before:rounded before:absolute before:right-[-34px] before:top-[25%] before:translate-y-[-50%]">
                                            {left_content[1]}
                                        </p>
                                        {title.includes('Sourcing') &&
                                            <div className="content-box bg-white-200 shadow-1xl rounded-[0.625rem] p-[0.625rem] md:p-5 inline-block">
                                                <ul className="flex flex-wrap items-center justify-between">
                                                    <li className="inline-block md:mr-[1.688rem] mb-1 md:mb-0">
                                                        <StaticImage
                                                            src="../assets/img/indeed.webp"
                                                            alt="Awarded as top web developers in India 2019."
                                                            placeholder="blurred"
                                                        />
                                                    </li>
                                                    <li className="inline-block md:mr-[1.688rem] mb-1 md:mb-0">
                                                        <StaticImage
                                                            src="../assets/img/google-logo.webp"
                                                            alt="Awarded as top web developers in India 2019."
                                                            placeholder="blurred"
                                                        />
                                                    </li>
                                                    <li className="inline-blockmd md:mr-[1.688rem] mb-1 md:mb-0">
                                                        <StaticImage
                                                            src="../assets/img/linkedin-ful.webp"
                                                            alt="Awarded as top web developers in India 2019."
                                                            placeholder="blurred"
                                                        />
                                                    </li>
                                                    <li className=" py-[0.375rem] px-[0.625rem] rounded-[3.75rem] text-xs text-black bg-[#F5F3FF] inline-block">other platforms</li>
                                                </ul>
                                            </div>
                                        }
                                        {title.includes('Screening') && <div className="content-box bg-white-200 shadow-1xl rounded-[0.625rem] p-5 inline-block">
                                            <div className="candidate-img inline-block align-middle">
                                                <span className="img-container inline-block w-[2.625rem] h-[2.625rem] rounded-full border-2 border-white bg-white-300 overflow-hidden">
                                                    <StaticImage
                                                        src="../assets/img/candidate-1.webp"
                                                        className="w-full h-full mt-[0.063rem] object-cover"
                                                        alt="Candidate" />
                                                </span>
                                                <span className="img-container inline-block w-[2.625rem] h-[2.625rem] rounded-full border-2 border-white bg-white-300 overflow-hidden relative ml-[-0.75rem]">
                                                    <StaticImage
                                                        src="../assets/img/candidate-2.webp"
                                                        className="w-full h-full mt-[0.063rem] object-cover"
                                                        alt="Candidate" />
                                                </span>
                                                <span className="img-container inline-block w-[2.625rem] h-[2.625rem] rounded-full border-2 border-white bg-white-300 overflow-hidden relative ml-[-0.75rem]">
                                                    <StaticImage
                                                        src="../assets/img/candidate-3.webp"
                                                        className="w-full h-full mt-[0.063rem] object-cover"
                                                        alt="Candidate" />
                                                </span>
                                            </div>
                                            <p className="text-base text-black inline-block align-middle pl-[0.625rem] max-md:text-center"><strong className="font-gorditabold">1000</strong> Applied Candidates</p>
                                        </div>}
                                    </div>
                                    <div className="flow-content right-align pl-[0.875rem] mb-[80px] md:pb-0 md:pl-[1.875rem] pt-[3.125rem]">
                                        {right_content[0] && <span className="flow-label inline-block border border-black bg-cream rounded-[3.688rem] py-2 md:pb-[6px] md:py-[0.563rem] px-[1.375rem] md:px-[1.563rem] text-xs md:text-sm leading-none  text-center ml-auto mb-5 italic">
                                            {right_content[0]}
                                        </span>}
                                        <p className="text-base md:text-lg lg:text-2xl leading-6 md:leading-[2.125rem] mb-5 md:mb-[1.875rem] max-w-[27.75rem] mr-auto">
                                            {right_content[1]}
                                        </p>
                                        {(title.includes('Screening') || title.includes('Interviewing')) &&
                                            <div className="content-box bg-white-200 shadow-1xl rounded-[0.625rem] p-[0.875rem] md:p-5 inline-block max-md:min-w-0 min-w-[18.75rem] md:mb-20 relative">
                                                <div className="img-container w-[2.688rem] h-[2.688rem] md:w-[3.875rem] md:h-[3.875rem] bg-[#f7f8f9] rounded-full overflow-hidden align-middle inline-block mb-[10px] md:mb-0">
                                                    <StaticImage
                                                        src="../assets/img/DSC_029912 1.webp"
                                                        className=" mt-[0.063rem] object-contain w-full h-full"
                                                        alt="Techuz developers" />
                                                </div>
                                                <div className="candidate-detail align-middle inline-block md:pl-4 relative">
                                                    <span className="joined-label absolute top-[-33px] md:top-[-2rem] left-[-30px] md:left-0 w-[8.5rem] hidden md:block">
                                                        <StaticImage
                                                            src="../assets/img/expert-label.webp"
                                                            className=" mt-[0.063rem] object-contain w-full h-full"
                                                            alt="Techuz developers" />
                                                    </span>
                                                    <p className="text-black text-sm md:text-lg font-gorditamedium mb-[0.375rem] md:mb-[0.563rem] leading-none">Mayank Mishra</p>
                                                    <p className="job-position text-xs md:text-sm leading-none">Senior React Developer</p>
                                                    <span className="experience text-[#A0A0A0] font-gorditamedium italic text-xs">15+ yr exp.</span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>


                    <div className="hiring-table pt-[3.75rem]">
                        <div className="table-head">
                            <ul className="grid grid-cols-2 md:grid-cols-3 mb-5 justify-center items-center">
                                <li className="max-md:hidden"></li>
                                <li className="text-center">
                                    <span className="inline-block italic py-2 px-[1.125rem] md:px-[15px] lg:px-6 rounded-[6.25rem] bg-[#FFF2E3] text-sm lg:text-base font-gorditamedium leading-[1.125rem] md:leading-[1.813rem] text-left md:text-center h-[52px] md:h-auto max-md:flex max-md-flex-wrap max-md:items-center max-md:justify-center max-md:max-w-[150px]">Traditional hiring</span>
                                </li>
                                <li className="text-center">
                                    <span className="inline-block gap-[1.188rem] italic py-2 px-[1.125rem] md:px-[15px] lg:px-6 rounded-[6.25rem] bg-[#E8E3FF] text-sm lg:text-base font-gorditamedium leading-[1.125rem] md:leading-[1.813rem] text-left md:text-center">Hiring developers with Techuz</span>
                                </li>
                            </ul>
                        </div>
                        <div className="table-body md:rounded-[1.25rem] md:border border-black overflow-hidden relative before:content-[''] before:w-[1px] before:h-full before:bg-[url('../assets/img/grad-line.webp')] md:before:bg-center before:absolute before:right-[33.33%] before:top-[50%] before:translate-y-[-50%] before:bg-no-repeat max-md:before:hidden">
                            <ul className="grid grid-cols-2 md:grid-cols-3">
                                <li className="text-sm md:text-sm lg:text-xl italic py-[0.625rem] md:py-[0.938rem] font-gorditamedium leading-[1.313rem] md:leading-[1.688rem] text-black relative md:pl-[2.313rem] max-md:col-span-2 flex gap-[0.313rem] md:gap-[1.188rem]"><span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-4 w-4 block md:mt-1"></span>Hiring time</li>
                                <li className="text-center text-base md:text-lg lg:text-xl leading-6 md:leading-[1.688rem] py-[1.125rem] md:py-[0.938rem bg-[#F8F7F3] md:px-6 px-5">30 days</li>
                                <li className="text-center text-base md:text-lg lg:text-xl leading-6 md:leading-[1.688rem] py-[1.125rem] md:py-[0.938rem] bg-[#F8F7F3] md:px-6 px-5">5-7 days</li>
                            </ul>
                            <ul className="grid grid-cols-2 md:grid-cols-3">
                                <li className="text-sm md:text-sm lg:text-xl italic py-[0.625rem] md:py-[0.938rem] font-gorditamedium leading-[1.313rem] md:leading-[1.688rem] text-black relative md:pl-[2.313rem] max-md:col-span-2 flex gap-[0.313rem] md:gap-[1.188rem]"><span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-4 w-4 block md:mt-1"></span>Hiring cost</li>
                                <li className="text-center text-base md:text-lg lg:text-xl leading-6 md:leading-[1.688rem] py-[1.125rem] md:py-[0.938rem bg-[#F8F7F3] md:px-6 px-5">High</li>
                                <li className="text-center text-base md:text-lg lg:text-xl leading-6 md:leading-[1.688rem] py-[1.125rem] md:py-[0.938rem bg-[#F8F7F3] md:px-6 px-5">None</li>
                            </ul>
                            <ul className="grid grid-cols-2 md:grid-cols-3">
                                <li className="text-sm md:text-sm lg:text-xl italic py-[0.625rem] md:py-[0.938rem] font-gorditamedium leading-[1.313rem] md:leading-[1.688rem] text-black relative md:pl-[2.313rem] max-md:col-span-2 flex gap-[0.313rem] md:gap-[1.188rem]"><span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-4 w-4 block md:mt-1"></span>Flexibility</li>
                                <li className="text-center text-base md:text-lg lg:text-xl leading-6 md:leading-[1.688rem] py-[1.125rem] md:py-[0.938rem bg-[#F8F7F3] md:px-6 px-5">None</li>
                                <li className="text-center text-base md:text-lg lg:text-xl leading-6 md:leading-[1.688rem] py-[1.125rem] md:py-[0.938rem bg-[#F8F7F3] md:px-6 px-5">One email or call to scale up/down</li>
                            </ul>
                            <ul className="grid grid-cols-2 md:grid-cols-3">
                                <li className="text-sm md:text-sm lg:text-xl italic py-[0.625rem] md:py-[0.938rem] font-gorditamedium leading-[1.313rem] md:leading-[1.688rem] text-black relative md:pl-[2.313rem] max-md:col-span-2 flex gap-[0.313rem] md:gap-[1.188rem]"><span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-4 w-4 block md:mt-1"></span>Trial</li>
                                <li className="text-center text-base md:text-lg lg:text-xl leading-6 md:leading-[1.688rem] py-[1.125rem] md:py-[0.938rem bg-[#F8F7F3] md:px-6 px-5">None</li>
                                <li className="text-center text-base md:text-lg lg:text-xl leading-6 md:leading-[1.688rem] py-[1.125rem] md:py-[0.938rem bg-[#F8F7F3] md:px-6 px-5"><span className="font-gorditamedium inline-block text-sm text-white bg-black italic py-1 pl-[0.813rem] pr-[1.063rem] rounded-[6.25rem] leading-[1.813rem]">~ 7 days free trial</span></li>
                            </ul>
                            <ul className="grid grid-cols-2 md:grid-cols-3">
                                <li className="text-sm md:text-sm lg:text-xl italic py-[0.625rem] md:py-[0.938rem] font-gorditamedium leading-[1.313rem] md:leading-[1.688rem] text-black relative md:pl-[2.313rem] max-md:col-span-2 flex gap-[0.313rem] md:gap-[1.188rem]"><span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-4 w-4 block md:mt-1"></span>Compensation considerations</li>
                                <li className="text-center text-base md:text-lg lg:text-xl leading-6 md:leading-[1.688rem] py-[1.125rem] md:py-[0.938rem bg-[#F8F7F3] md:px-6 px-5">Salary, benefits,hikes</li>
                                <li className="text-center text-base md:text-lg lg:text-xl leading-6 md:leading-[1.688rem] py-[1.125rem] md:py-[0.938rem bg-[#F8F7F3] md:px-6 px-5">Fixed, simple, and transparent invoice</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="lets-get-started pt-10 lg:pt-[6.875rem] pb-20 lg:pb-[3.438rem]">
                <div className="container">
                    <div className="title-wrap mb-[3.125rem]">
                        <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-0 font-blancomedium text-center">
                            {post.journey_steps.title.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                            <span className="inline-block text-orangecol">.</span></h2>
                    </div>
                    <div className="steps-grid grid md:grid-cols-3 gap-10">
                        {post.journey_steps.steps.map(({ text1, text2 }, idx) => (
                            <div key={idx} className={`steps-item md:text-center relative max-md:pl-16 ${idx < post.journey_steps.steps.length - 1 ? "before:content-[''] before:w-full before:h-[0.063rem] before:bg-black before:absolute max-lg:before:top-6 before:top-[1.875rem] before:z-[-1] before:left-[calc(50%+20px)] max-md:before:h-[calc(100%+20px)] max-md:before:w-[0.063rem] max-md:before:left-6 max-md:translate-x-0" : ""}`}>
                                <span className="max-md:absolute max-md:left-0 max-md:-top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] border border-black rounded-[50%] flex items-center justify-center my-0 mx-auto mb-10 bg-[#ffffff] font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">{idx + 1}</span>
                                <h3 className="text-lg md:text-xl lg:text-2xl md:leading-[1.688rem] lg:leading-[2.188rem] mb-[0.625rem] md:mb-5 font-gorditamedium">
                                    {text1}
                                </h3>
                                <p className="leading-[1.625rem]">
                                    {text2}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section className="py-10 md:py-12 lg:py-14">
                <div className="container">
                    <div className="flex flex-wrap border border-cream items-center justify-between gap-10 px-[1.875rem] pt-10 pb-[3.125rem] md:pt-[3.25rem] md:pb-[3.625rem] md:px-[4.375rem] md:bg-[url('../assets/img/tech-bg.webp')] bg-[url('../assets/img/tech-bg-mob.webp')] rounded-[1.25rem] overflow-hidden">
                        <h2 className="font-blancomedium text-[1.75rem] md:text-3xl lg:text-4xl w-full md:w-[33.125rem] leading-[2.938rem]">
                            {post.team_building.text.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                        </h2>
                        <Link
                            to="/contact-us/"
                            className="inline-flex items-center bg-white-200 justify-between inline-white text-black border border-white shadow-1xl max-sm:text-sm text-base rounded-3xl pl-5 py-1 pr-[0.313rem] font-gorditamedium group"
                        >
                            {post.team_building.button_text}
                            <span className="inline-flex w-[2.125rem] h-[2.125rem] bg-primary border-2 border-primary justify-center items-center rounded-full ml-[0.625rem] ease-in duration-300 group-hover:bg-transparent relative">
                                <img
                                    src={animatedIconWhite}
                                    alt="Amnimated icon"
                                    className="w-5 h-5 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-100 visible group-hover:opacity-0 group-hover:invisible transition-all ease-in duration-300"
                                />
                                <img
                                    src={animatedIcon}
                                    alt="Animated icon"
                                    className="w-5 h-5 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all ease-in duration-300"
                                />
                            </span>
                        </Link>
                    </div>
                </div>
            </section>

            <Faq faqData={post.faqs} />

            <Contact />
        </>
    )
}

export const query = graphql`
  query ($slug: String!) {
    pseoWebDeveloperJson(slug: { eq: $slug }) {
      slug
      seo_title
      seo_description
      title_1
      description_1
      button_text
      hiring {
        title
        description
        points {
            pointImage {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
                publicURL
            }    
            title
            description
        }
      }
      build_team {
        text
        button_text
      }
      top_developer {
        title
        description
        screening_text
        screening_tag
        review_text
        review_tag
        live_task_text
        live_task_tag
        consitent_work_text
        consitent_work_tag
      }
      best_web_developers_text
      best_web_developers_button_text
      reasons_to_choose {
        title
        box_quote
        box_bottom
        points {
            icon {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
                publicURL
            }
            title
            description
        }
      }
      recruit {
        text
        button_text
      }
      hire_developer_text
      clutch_title
      clutch_description
      hiring_benefits {
        title
        points {
            title
            left_content
            right_content
        }
      }
      journey_steps {
        title
        steps {
          text1
          text2
        }
      }
      team_building {
        text
        button_text
      }
      ecosystem {
        title
        left_div {
            title
            children {
                text
                techImage {
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED)
                    }
                    publicURL
                    extension
                }
            }
        }
        right_div {
            title
            children {
                text
                techImage {
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED)
                    }
                    publicURL
                    extension
                }
            }
        }
      }
      faqs {
        key
        question
        answer
      }
    }
  }
`
export default PseoWebDeveloperPage

export const Head: HeadFC = ({ location, data }) => {
    const { siteUrl } = useSiteMetadata()
    const { seo_title, seo_description } = data["pseoWebDeveloperJson"]

    return (
        <SEO
            title={seo_title}
            canonical={`${siteUrl}${location.pathname}`}
            description={seo_description}
        />
    )
}
